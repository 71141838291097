<template>
  <div
    class="button-block r-cover md:w-50vw min-h-40vh overflow-hidden flex justify-center items-center"
    :class="`${name}`"
    :style="`background-color: ${content.background_colour};`"
  >
    <img
      class="absolute top-0 w-auto max-w-none h-full opacity-0"
      :class="[
        `${name}__dec`,
        decDir === 'left' ? 'left-0 md:right-0 md:left-auto' : 'right-0'
      ]"
      :src="content.dec_graphic.url"
      :alt="content.dec_graphic.alt"
    />
    <img
      class="absolute bottom-0 w-auto max-w-none h-90% mx-auto md:hidden opacity-0"
      :class="`${name}__img`"
      :src="content.graphic_mobile.url"
      :alt="content.graphic_mobile.alt"
    />
    <Button
      class="top-10 md:top-0 backface-hidden opacity-0 bg-secondary py-3"
      :class="`${name}__btn`"
      :button="{
        ...content,
        button_link: link,
        button_label: `View ${content.button_text} page`
      }"
    />
  </div>
</template>

<script>
import { gsap } from 'gsap'

import Button from '@/components/buttons/Button.vue'

export default {
  name: 'ButtonBlock',

  components: {
    Button
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    content: {
      type: Object,
      default: () => {}
    },
    link: {
      type: String,
      default: '/'
    },
    delay: {
      type: Number,
      default: 2
    },
    decDir: {
      type: String,
      default: 'right'
    }
  },

  mounted() {
    const decDelay = this.delay + 1
    const imgDelay = this.delay + 1.4
    // Animate button block bgs - if (this.decDir === 'left') {
    //   gsap.fromTo(`.${this.name}`, { opacity: 0, x: '100%' }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' })
    // } else {
    //   gsap.fromTo(`.${this.name}`, { opacity: 0, x: '-100%' }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' })
    // }
    gsap.fromTo(`.${this.name}__btn`, { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: this.delay, duration: 0.4, ease: 'power1.inOut' })
    gsap.fromTo(`.${this.name}__dec`, { opacity: 0, y: -100 }, { opacity: 1, y: 0, delay: decDelay, duration: 0.4, ease: 'power1.inOut' })
    gsap.fromTo(`.${this.name}__img`, { opacity: 0, x: 50 }, { opacity: 1, x: 0, delay: imgDelay, duration: 0.4, ease: 'power1.inOut' })
  }
}
</script>

<style lang="scss" scoped>
  .button-block {
    transition: 0.8s all;
    @screen md {
      &:hover {
        width: 70%;
      }
    }
  }

  .backface-hidden {
    backface-visibility: hidden;
  }
</style>
