<template>
<div>
  <div class="flex flex-col justify-center items-center sm:overflow-hidden sm:h-screen">
    <div v-if="content.background_media.background_type === 'Video'" class="hidden sm:block">
      <video id="backgroundVideo" class="max-w-100%" autoplay loop muted :poster="content.background_media.background_video.poster_image.url" @playing="updatePaused" @pause="updatePaused" @canplay="updatePaused">
        <source :src="content.background_media.background_video.mp4_source.url" type="video/mp4" />
      </video>
      <div class="hidden sm:block">
        <button
          class="button-square w-14 h-14 flex justify-center content-center absolute bottom-10 right-10 text-center p-6 block bg-secondary cursor-pointer text-sm font-bold"
          @click="togglePlay"
        >
          <div class="back-icon flex place-self-center">
            <IconBase
              v-show="!paused"
              icon="IconPause"
              size="25px"
              class="place-self-center"
            />
            <IconBase
              v-show="paused"
              icon="IconPlay"
              size="25px"
              class="place-self-center"
            />
          </div>
        </button>
      </div>
    </div>
    <div v-if="content.background_media.background_type === 'Image'" class="hidden sm:block">
      <img id="backgroundVideo" :src="content.background_media.background_video.poster_image.url" />
    </div>
    <div
      class="hero flex flex-col justify-between w-screen pt-10vh pb-10vh sm:pt-10 sm:pb-10 sm:w-45vw"
      :style="`background-color: ${content.hero.background_colour};`"
    >
      <div class="text-center self-center">
        <h1 class="hero__title text-accent-4 athelas-font text-center text-5xl md:text-6xl leading-none">
          {{ content.hero.heading_large }}
        </h1>
        <h4 class="hero__secondary text-accent-4 athelas-font text-center text-xl md:text-2xl leading-none mb-4">
          {{ content.hero.heading }}
        </h4>
        <p class="hero__text md:max-w-lg text-md md:text-xl text-white px-4 justify-self-center self-center">
          {{ content.hero.text }}
        </p>
      </div>
    </div>  
      <div class="hidden sm:block sm:flex sm:w-45vw">
        <Button
          class="top-10 md:top-0 backface-hidden opacity-0 bg-secondary absolute-bottom w-50% py-5"
          :class="`ButtonBlock2__btn`"
          :button="{
          ...content.button_block_left,
          button_link: '/explore',
          button_label: `View ${content.button_block_left.button_text} page`
        }"/>
        <Button
          class="top-10 md:top-0 backface-hidden opacity-0 bg-white w-50% py-5"
          :class="`ButtonBlock1__btn`"
          :button="{
          ...content.button_block_right,
          button_link: '/guided-tours',
          button_label: `View ${content.button_block_right.button_text} page`
        }"/>
      </div>
      </div>
      <div class="sm:hidden ">
        <ButtonBlock
        name="ButtonBlock1"
        class="block-1 overflow-hidden"
        :content="content.button_block_left"
        link="/explore"
        :delay="1.4"
        />
        <ButtonBlock
        name="ButtonBlock2"
        class="block-2"
        :content="content.button_block_right"
        link="/guided-tours"
        decDir="left"
        :delay="1.8"
        />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

import Button from '@/components/buttons/Button.vue'
import IconBase from '@/components/icons/IconBase.vue'
import ButtonBlock from '@/components/blocks/ButtonBlock.vue'

export default {
  name: 'Home',

  components: {
    Button,
    IconBase,
    ButtonBlock
  },

  data() {
    return {
    videoElement : null,
    paused : null
    }
  },

  methods: {
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    togglePlay() {
      this.paused ? this.videoElement.play() : this.videoElement.pause()
    },
  },

  computed: {
    ...mapGetters(['homepageContent']),
    content() {
      return this.homepageContent.acf
    }
  },

  mounted() {
    // Animate hero bg - gsap.fromTo('.hero', { opacity: 0, x: '-100%' }, { opacity: 1, x: 0, delay: 0.1, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.hero__title', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 0.8, duration: 1, ease: 'power1.inOut' })
    gsap.fromTo('.hero__secondary', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 1, duration: 1, ease: 'power1.inOut' })
    gsap.fromTo('.hero__text', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 1.2, duration: 1, ease: 'power1.inOut' })
  }
}
</script>

<style lang="scss">
#backgroundVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

#video-button {
  width: 33px;
  height: 32px;
  transition: .8s background-color,
              .8s color;
  &:focus,
  &:hover {
    background-color: var(--color-black);
    color: var(--color-white);

    .menu-icon__item {
      background-color: var(--color-white);
    }
  }

  &:focus {
    box-shadow: none;
  }
}
</style> 
